require('../../scss/frontend/services.scss');

$(document).ready(function (){
    $("div.image-1").mouseover(function(){
        $("div.div-principale").removeClass('one two three').addClass('one');
    })
    $("div.image-2").mouseover(function(){
        $("div.div-principale").removeClass('one two three').addClass('two');
    })
    $("div.image-3").mouseover(function(){
        $("div.div-principale").removeClass('one two three').addClass('three');
    })
})